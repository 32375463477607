export default {
    name: 'WatAcademyMedal',
    props: {
        medal: {
            type: Object,
            default: function () { }
        },
        index: {
            type: Number,
            default: 0
        }
    }
};
